import { MeasurePanel } from "./MeasurePanel.js";

export class AnglePanel extends MeasurePanel {
  constructor(viewer, measurement, propertiesPanel) {
    super(viewer, measurement, propertiesPanel);

    let removeIconPath = Potree.resourcePath + "/icons/remove.svg";
    this.elContent = $(`
			<div class="measurement_content selectable">
				<span class="coordinates_table_container"></span>
				<br>
				<table class="measurement_value_table">
					<tr>
						<th>\u03b1</th>
						<th>\u03b2</th>
						<th>\u03b3</th>
					</tr>
					<tr>
						<td align="center" id="angle_cell_alpha" style="width: 33%"></td>
						<td align="center" id="angle_cell_betta" style="width: 33%"></td>
						<td align="center" id="angle_cell_gamma" style="width: 33%"></td>
					</tr>
				</table>

				<!-- ACTIONS -->
				<div style="display: flex; margin-top: 12px">
					<span></span>
					<span style="flex-grow: 1"></span>
					<img name="remove" class="button-icon" src="${removeIconPath}" style="width: 16px; height: 16px"/>
				</div>
			</div>
		`);

    this.elRemove = this.elContent.find("img[name=remove]");
    this.elRemove.click(() => {
      this.viewer.scene.removeMeasurement(measurement);
    });

    this.propertiesPanel.addVolatileListener(
      measurement,
      "marker_added",
      this._update
    );
    this.propertiesPanel.addVolatileListener(
      measurement,
      "marker_removed",
      this._update
    );
    this.propertiesPanel.addVolatileListener(
      measurement,
      "marker_moved",
      this._update
    );

    this.update();
  }

  update() {
    let elCoordiantesContainer = this.elContent.find(
      ".coordinates_table_container"
    );
    elCoordiantesContainer.empty();
    elCoordiantesContainer.append(
      this.createCoordinatesTable(this.measurement.points.map(p => p.position))
    );

    let angles = [];
    for (let i = 0; i < this.measurement.points.length; i++) {
      angles.push(this.measurement.getAngle(i) * (180.0 / Math.PI));
    }
    angles = angles.map(a => a.toFixed(1) + "\u00B0");

    let elAlpha = this.elContent.find(`#angle_cell_alpha`);
    let elBetta = this.elContent.find(`#angle_cell_betta`);
    let elGamma = this.elContent.find(`#angle_cell_gamma`);

    elAlpha.html(angles[0]);
    elBetta.html(angles[1]);
    elGamma.html(angles[2]);
  }
}
